$side-padding-xs: 30px;
$side-padding-sm: 60px;
$side-padding: 100px;
$side-padding-lg: 160px;
/******************************************************************************
* XL devices
*****************************************************************************/
@media (min-width: 1200px) {
}
/******************************************************************************
* LG devices
*****************************************************************************/
@media (max-width: 1199px) {
  /**
	* Home
	*/
  .video-holder {
    width: 400px;
    .video-control {
      width: 400px;
    }
  }
  .timeline {
    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 2%;
    }

    .image-point-row:nth-child(5) .content {
      left: 54%;
    }
  }

  .section-stories {
    .story-head {
      .story-holder {
        top: 30px;
        right: 0px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }
  /**
	* About
	*/
  .team-member {
    margin-bottom: 20px;
  }
}
/******************************************************************************
* MD devices
*****************************************************************************/
$h1-font-size-md: 68px;
$h2-font-size-md: 42px;
$h3-font-size-md: 36px;
$h4-font-size-md: 24px;
$h5-font-size-md: 22px;
$h6-font-size-md: 18px;

$md-body-font-size: 14px;
$md-body-font-size-md: 16px;
$md-body-font-size-lg: 18px;
@media (max-width: 991px) {
  /**
	* General
	*/
  .bottom-space {
    padding-bottom: 40px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }
  }

  .small-header {
    min-height: 250px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }

  /**
	* Nav
	*/
  #mainNav {
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;
  }

  /**
	* Footer
	*/
  footer {
    &.page-footer {
      padding: $side-padding-sm;
    }
  }

  /**
	* Home
	*/
  .hero-header {
    padding: $side-padding-xs;
    padding-top: 150px;
  }
  .timeline {
    .content {
      position: absolute;
      width: 300px;
    }

    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 32%;
    }

    .image-point-row:nth-child(5) .content {
      left: 32%;
    }
  }

  .video-holder {
    width: 700px;
    margin-top: 50px;
    .video-control {
      width: 700px;
    }
  }
  .section-stories {
    .story-head {
      .current-img-holder {
        height: 150px;

        .current-story-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .overlay {
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .story-holder {
        position: static;
        top: 0;
        right: 0;
        padding-bottom: 10px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }

  /**
	* About
	*/
  .team-member {
    margin-bottom: 20px;
  }

  /**
	* Loans
	*/
  .plans-row {
    .card {
      margin-bottom: 30px;
    }
  }

  .sidebar-toggler {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 100px;
  }

  .dashboard-sidebar {
    .links-col {
      .dashboard-links {
        .list-group-item {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 15px;
        }
      }
    }
  }
}
/******************************************************************************
* SM devices
*****************************************************************************/
$h1-font-size-sm: 68px;
$h2-font-size-sm: 42px;
$h3-font-size-sm: 36px;
$h4-font-size-sm: 24px;
$h5-font-size-sm: 22px;
$h6-font-size-sm: 18px;

$sm-body-font-size: 14px;
$sm-body-font-size-md: 16px;
$sm-body-font-size-lg: 18px;

@media (max-width: 767px) {
  /**
	* General
	*/
  .bottom-space {
    padding-bottom: 40px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-xs;
      padding-bottom: $side-padding-xs;
    }
  }

  .small-header {
    min-height: 250px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 200%;
      height: 100%;
      left: -350px;
    }
  }

  /**
	* Nav
	*/
  #mainNav {
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;
  }

  /**
	* Footer
	*/
  footer {
    &.page-footer {
      padding: $side-padding-xs;

      .divider-row {
        border-left: 0 !important;
        &::before {
          border-left: 0 !important;
        }
      }
    }
  }

  /**
	* Home
	*/
  .hero-header {
    padding: $side-padding-xs;
    padding-top: 150px;
  }
  .timeline {
    .content {
      position: absolute;
      width: 300px;
    }

    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 25%;
    }

    .image-point-row:nth-child(5) .content {
      left: 25%;
    }
  }

  .video-holder {
    width: 500px;

    .video-control {
      width: 500px;
    }
  }
  .section-stories {
    .story-head {
      .current-img-holder {
        height: 150px;

        .current-story-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .overlay {
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .story-holder {
        position: static;
        top: 0;
        right: 0;
        padding-bottom: 10px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }

  /**
	* About
	*/
  .mission-vision-section {
    border-right: 2px solid;
    div {
      padding: $side-padding-xs;

      &:first-child {
        p {
          position: relative;
          right: 30px;
        }
      }

      p {
        width: 300px;
      }
    }
  }

  .team-member {
    margin-bottom: 20px;
  }

  /**
	* Loans
	*/
  .check-list {
    li {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
  .plans-row {
    .card {
      margin-bottom: 30px;
    }
  }
}
/******************************************************************************
* XS devices
*****************************************************************************/
$h1-font-size-xs: 68px;
$h2-font-size-xs: 36px;
$h3-font-size-xs: 24px;
$h4-font-size-xs: 16px;
$h5-font-size-xs: 22px;
$h6-font-size-xs: 18px;

$xs-body-font-size: 14px;
$xs-body-font-size-md: 16px;
$xs-body-font-size-lg: 18px;

@media (max-width: 576px) {
  /**
	* General Design
	*/
  .font-md {
    font-size: $xs-body-font-size-md;
  }

  .font-lg {
    font-size: $xs-body-font-size-lg;
  }

  h1 {
    font-size: $h1-font-size-xs;
  }

  h2 {
    font-size: $h2-font-size-xs;
  }

  h3 {
    font-size: $h3-font-size-xs;
  }

  h4 {
    font-size: $h4-font-size-xs;
  }

  h5 {
    font-size: $h5-font-size-xs;
  }

  h6 {
    font-size: $h6-font-size-xs;
  }

  .bottom-space {
    padding-bottom: 60px;
  }

  .bottom-space-sm {
    padding-bottom: 30px;
  }

  .section {
    padding: 50px;
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;

    &.large-vertical-padding {
      padding-top: $side-padding-sm;
      padding-bottom: $side-padding-sm;
    }

    &.small-vertical-padding {
      padding-top: $side-padding-xs;
      padding-bottom: $side-padding-xs;
    }
  }

  .small-header {
    min-height: 250px;

    .small-header-overlay {
      height: 250px;
      padding-top: 90px;

      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 28px;
      }

      p.font-lg {
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      width: 300%;
      height: 100%;
      left: -350px;
    }
  }
  /**
	* Nav
	*/
  #mainNav {
    padding-left: $side-padding-xs;
    padding-right: $side-padding-xs;
  }

  /**
	* Footer
	*/
  footer {
    &.page-footer {
      padding: $side-padding-xs;

      .divider-row {
        border-left: 0 !important;
        &::before {
          border-left: 0 !important;
        }
      }
    }
  }
  /**
	* Home
	*/
  .hero-header {
    padding: $side-padding-xs;
    padding-top: 150px;
  }
  .timeline {
    .content {
      position: absolute;
      width: 300px;
    }

    .image-point-row:nth-child(3) .content,
    .image-point-row:nth-child(7) .content {
      left: 20px;
    }

    .image-point-row:nth-child(5) .content {
      left: 20px;
    }
  }

  .video-holder {
    width: 300px;

    .video-control {
      width: 300px;
    }
  }

  .section-stories {
    .story-head {
      .current-img-holder {
        height: 150px;

        .current-story-img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .overlay {
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .story-holder {
        position: static;
        top: 0;
        right: 0;
        padding-bottom: 150px;

        .story-card {
          position: relative;
        }
      }
    }

    .roller-row {
      overflow-x: scroll;
    }
  }
  /**
	* About
	*/
  .mission-vision-section {
    div {
      padding: $side-padding-xs;

      &:first-child {
        p {
          position: relative;
          right: 30px;
        }
      }

      p {
        width: 300px;
      }
    }
  }

  .team-member {
    margin-bottom: 20px;
  }

  /**
	* Loans
	*/
  .check-list {
    li {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }
  .plans-row {
    .card {
      margin-bottom: 30px;
    }
  }

  .request-complete-modal {
    .main-content {
      padding-top: 30px;
      height: 400px;
    }
  }
}

// Gbenga's Media Query

@media (max-width: 900px) {
  .blog-section {
    margin-top: 50px;
  }
  .pitch-video {
    video {
      max-height: 200px !important;
      max-width: 100%;
    }
  }
  #registerImage {
    display: none;
  }
  .user-layout {
    display: grid;
    grid-template-columns: 1fr;
  }

  .dashboard-main {
    margin-top: 20px;
    padding: 10px;
  }

  .registration-stepper {
    .active {
      width: 70%;
      margin: 0 auto;
    }
  }

  .dashboard-middle {
    grid-template-columns: 1fr;
  }

  .admin-dashboard-middle {
    grid-template-columns: 1fr;
  }

  .position-chart {
    .chartjs-render-monitor {
      min-height: unset !important;
      max-height: unset !important;
    }
  }

  .dashboard-bottom {
    grid-template-columns: 1fr;
  }

  .dashboard-header-content:nth-child(1),
  .dashboard-header-content:nth-child(2) {
    border-right: unset;
    border-bottom: 1px solid #ec008c;
  }
  .dashboard-header-content {
    padding-top: 10px;
  }
  .manage-profile {
    padding: 50px 5px;

    .manage-profile-header {
      h6 {
        margin-right: 20px;
        font-size: 14px;
      }
    }

    .manage-profile-card {
      padding: 20px;
    }
  }
  .loan-table {
    padding: 5px;
  }
  .sources-of-income-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .sources-of-income-body {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 20px;
  }
  .sources-of-income-navigation-buttons {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;

    div {
      padding: 5px 2px;
    }
  }

  //Admin Layout
  .admin-layout {
    grid-template-columns: 1fr 5fr;
  }
  .admin-main {
    margin-top: 100px;
    padding: 20px;

    .admin-loan-details {
      grid-template-columns: 1fr;
    }
  }
  .admin-sidebar {
    padding: 10px 0px;

    .place-sidebar-icons {
      margin-top: 80px;

      a {
        i {
          font-size: 250%;
          padding: 15px 0px;
        }
      }
    }
  }
  .admin-sidebar {
    padding: 10px 0px;

    .place-sidebar-icons {
      margin-top: 80px;

      button {
        i {
          font-size: 250%;
          padding: 15px 0px;
        }
      }
    }
  }
}
