@font-face {
  font-family: nonito-light;
  src: url("../fonts/Nunito/Nunito-Light.ttf");
}
@font-face {
  font-family: nonito-regular;
  src: url("../fonts/Nunito/Nunito-Regular.ttf");
}
@font-face {
  font-family: nonito-bold;
  src: url("../fonts/Nunito/Nunito-Bold.ttf");
}
/*******************************************************************************
*
* Variables
*
*****************************************************************************/
$app-name: shecluded;
$theme-font-regular: nonito-regular;
$theme-font-light: nonito-light;
$theme-font-bold: nonito-bold;

$theme-color: #ec008c;
$theme-color-alt: #c40074;
$theme-color-black: #000;
$theme-color-white: #fff;
$theme-color-light-grey: #e7e7e7;
$theme-color-dark-grey: #777;

$theme-text-color: $theme-color;
$theme-text-color-black: #000;
$theme-text-color-grey: $theme-color-dark-grey;
$theme-text-color-white: #fff;
$theme-text-color-disabled: #bdbdbd;

$theme-shadow-regular: 0px 5px 30px rgba(236, 0, 140, 0.15);
$theme-shadow-sm: 0px 5px 15px rgba(236, 0, 140, 0.15);
$alt-font: "Inter";

$theme-bg-white: #fff;
$theme-bg-grey: #fafafa;
$theme-bg-grey-mid: #dadada;
$theme-bg: #ec008c;

$h1-font-size: 68px;
$h2-font-size: 42px;
$h3-font-size: 36px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 18px;
$heading-xl: 7em;
$heading-l: 4em;

$body-font-size: 14px;
$body-font-size-md: 16px;
$body-font-size-lg: 18px;

$side-padding-xs: 30px;
$side-padding-sm: 100px;
$side-padding: 146px;
$side-padding-lg: 300px;
$side-padding-dashboard: 57px;
/*******************************************************************************
*
* General Deign
*
*****************************************************************************/
html,
body {
  width: 100%;
  height: 100%;
  line-height: 1.5;
  background-color: $theme-bg-white;
  color: $theme-text-color-black;
  font-family: $theme-font-regular;
  font-size: $body-font-size;
  padding: 0;
  margin: 0;
  letter-spacing: 0.08em;
  scroll-behavior: smooth;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $theme-bg;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $theme-color-alt;
  }
}

input,
textarea,
select {
  font-family: $alt-font !important;
}
.main-alert {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 992;

  .alert {
    display: block;
    width: 100%;
    padding: 25px;
    height: 90px;
    margin: 0;

    &.alert-success {
      background-color: rgba(236, 0, 140, 1);
      border: rgba(236, 0, 140, 0.7);
      color: #fff;
    }
  }
}

.large-vertical-padding {
  padding-bottom: $side-padding-sm;
}
.large-vertical-padding-top {
  padding-top: $side-padding-sm;
}
.small-vertical-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.error-text {
  color: $theme-color;
}

img.profile-picture {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.radio-select-row {
  label {
    margin-right: 30px;
    bottom: 3px;
  }

  input[type="radio"] {
    margin-right: 10px;
    border: 1px solid $theme-color;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;

  .modal-dialog {
    padding-top: 100px;
    width: 100%;
  }
}
/******************************************************************************
* Text
*****************************************************************************/
a,
a:hover,
a:active,
a:focus {
  color: $theme-text-color-black !important;
  text-decoration: none;
}

a.#{$app-name}-active {
  color: $theme-text-color !important;
  font-family: $theme-font-bold;
  border-bottom: 2px solid $theme-color;
}

a.text-#{$app-name} {
  color: $theme-text-color !important;
}

.font-md {
  font-size: $body-font-size-md;
}

.font-lg {
  font-size: $body-font-size-lg;
}

.alt-font {
  font-family: $alt-font;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.title {
    font-family: $theme-font-bold;
    &.dashboard {
      margin-left: $side-padding-dashboard;
      margin-bottom: 55px;
      &.small-bottom-margin {
        margin-bottom: 15px;
      }
    }
  }

  &.small-vertical-padding {
    padding-top: 0px !important;
    padding-bottom: 30px;
  }
}

.heading-xl {
  font-size: $heading-xl;
}

.heading-l {
  font-size: $heading-l;
}

.text-#{$app-name} {
  color: $theme-text-color;
}

.bottom-space {
  padding-bottom: 100px;
}

.bottom-space-sm {
  padding-bottom: 50px;
}
/******************************************************************************
* Buttons
*****************************************************************************/
.btn-#{$app-name} {
  background-color: $theme-bg;
  color: $theme-color-white !important;
  border: 1px solid $theme-color;
  transition: 0.4s;

  &:hover {
    background-color: $theme-color-alt;
    border: 1px solid $theme-color-alt;
    background-color: $theme-color-white;
    color: $theme-color-alt !important;
  }

  &.disabled {
    background-color: $theme-color-light-grey;
    color: $theme-text-color-disabled;
    border: 1px solid $theme-color-dark-grey;
  }
}

.btn-#{$app-name}-outline {
  background-color: $theme-color-white;
  color: $theme-text-color !important;
  border: 1px solid $theme-color;

  &:hover {
    color: $theme-color-alt !important;
    border: 1px solid $theme-color-alt;
  }

  &.disabled {
    color: $theme-text-color-disabled;
    border: 1px solid $theme-text-color-disabled;
  }
}
/******************************************************************************
* Containers
*****************************************************************************/
.main-container {
  &.no-header {
    padding-top: 100px;
  }
}

.hero-header {
  height: 589px;
  padding: $side-padding-sm;
  padding-top: 203px;

  &.small-vertical-padding {
    padding-top: $side-padding-xs;
    padding-bottom: $side-padding-xs;
  }

  .btn-#{$app-name} {
    margin-top: 20px;
    padding: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  h2 {
    margin-bottom: 20px;
    line-height: 55px;
  }

  .image-holder {
    width: 100%;
    height: 100%;
    position: relative;
    top: -80px;
    left: 178px;
    img {
      width: 440px;
      height: 440px;
    }
  }
}

.small-header {
  height: 400px;
  position: relative;
  overflow: hidden;
  padding-left: 0;

  .small-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 400px;
    padding-top: 100px;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
  }
}

.bullet-list {
  list-style-image: url(../../assets/list-bullet.svg);
}
.bullet-list li {
  border: 0;
  list-style-image: url(../../assets/list-bullet.svg);
  color: #222222;
}
.bullet-list li img {
  margin-right: 10px;
}

/******************************************************************************
* backgrounds
*****************************************************************************/
.bg-grey {
  background-color: $theme-bg-grey;
}

.bg-white {
  background-color: $theme-bg-white;
}

.bg-#{$app-name} {
  background-color: $theme-bg;
}
/******************************************************************************
* Sections
*****************************************************************************/
.section {
  padding: 50px;
  padding-left: $side-padding;
  padding-right: $side-padding;

  &.dashboard {
    padding-left: $side-padding-dashboard;
  }
  &.large-vertical-padding {
    padding-top: $side-padding-sm;
    padding-bottom: $side-padding-sm;
  }

  &.small-vertical-padding {
    padding-top: $side-padding-xs;
    padding-bottom: $side-padding-xs;
  }
}
/*******************************************************************************
*
* Navigation
*
*****************************************************************************/
#mainNav {
  transition: 0.4s;
  padding-left: $side-padding-sm;
  padding-right: $side-padding-sm;
  z-index: 990;
  &.bg-white {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    ul {
      li {
        a.text-white {
          color: $theme-text-color-black !important;
        }

        a.text-white.#{$app-name}-active {
          color: $theme-text-color !important;
        }
      }
    }

    .auth-nav-item {
      b.text-white {
        color: $theme-text-color-black;
      }
    }
  }
}

.nav-item {
  margin-right: 39px !important;
}

.auth-nav-item {
  padding-left: $side-padding-xs;
  position: relative;

  span {
    cursor: pointer;
    b {
      text-align: center;
      line-height: 40px;
      color: $theme-text-color-black;
    }
  }

  .auth-dropdown {
    display: block;
    position: absolute;
    top: 70px;
    width: 100%;

    .dropdown-item {
      cursor: pointer;
    }
  }

  .img-holder {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
}

/*******************************************************************************
*
* Footer
*
*****************************************************************************/
footer {
  &.page-footer {
    z-index: 999 !important;
    background-color: $theme-color-black;
    padding: $side-padding-sm;
    padding-bottom: 10px;
    overflow: hidden;

    .brand {
      position: relative;
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
      margin-bottom: 40px;

      .logo {
        width: 100px;
        height: 25px;
      }
    }

    .footer-subscribe-form {
      padding-bottom: 20px;
      padding-right: 50px;
    }

    .divider-col {
      border-left: 1.3px solid $theme-color-white;
      display: inline-block;
      height: 70%;
      margin-top: 30px;
    }

    .divider-row {
      border-left: 1.3px solid $theme-color-white !important;
      &::before {
        border-left: 2px solid $theme-color-white !important;
      }
    }

    %transparent-bg {
      background-color: transparent;
      border: 0;
      color: $theme-text-color-white;
    }
    ul {
      &.footer-form-info {
        @extend %transparent-bg;
        margin-top: 20px;

        &.wide-margin {
          margin-left: 20px;
          margin-right: 20px;
        }

        li {
          @extend %transparent-bg;

          a {
            color: $theme-text-color-white !important;
          }

          &.info-heading {
            h6 {
              color: $theme-text-color-white;
            }
          }

          i {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.social-nav {
  li {
    margin: 10px;

    a {
      &.social-link {
        display: block;
        background-color: rgb(48, 47, 47);
        border-radius: 100%;
        color: $theme-text-color-white;
        width: 40px;
        height: 40px;

        i {
          line-height: 40px;
          color: $theme-text-color-white;
        }
      }
    }
  }
}
/*******************************************************************************
*
* Home Page
*
*****************************************************************************/
.pitch-video {
  video {
    max-height: 300px !important;
    max-width: 100%;
    border-radius: 5px;
  }
}

.gif-women-holder {
  width: auto;
  overflow: hidden;
  height: 480px;
}
/******************************************************************************
* Focus Section
*****************************************************************************/
.focus-section {
  img {
    height: 220px;
    width: auto;
  }
}
/******************************************************************************
* How to join community Section
*****************************************************************************/
.timeline {
  margin-top: 60px;

  .badge.bg-#{$app-name} {
    margin-right: 10px;
  }

  .entry-point-row {
    padding-top: 150px;
    position: relative;

    & > div.content {
      position: absolute;
      top: 0;

      &::before {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $theme-bg-grey;
      }
    }
  }

  div.entry,
  div.end {
    width: 30px;
    height: 30px;
    background-color: $theme-bg;
    border-radius: 100%;
  }

  .content {
    position: absolute;
    width: 400px;
  }

  .connector-row {
    &.first {
      & > div {
        height: 80px;
      }
    }

    & > div {
      border-right: 2px dashed$theme-color;
      height: 150px;
    }
  }

  .image-point-row {
    position: relative;

    & > div.image-holder {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      border: 0;

      & > img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .image-point-row:nth-child(3) .content,
  .image-point-row:nth-child(7) .content {
    left: 17.5%;
  }

  .image-point-row:nth-child(5) .content {
    left: 53%;
  }
}

.mp-step-button {
  margin-bottom: 30px;
}
/******************************************************************************
* Featured Stories Section
*****************************************************************************/
.section-stories {
  .story-head {
    position: relative;

    .current-img-holder {
      height: 300px;
      overflow: hidden;
      position: relative;
      padding: 0;
      overflow: hidden;

      .current-story-img {
        position: absolute;
        width: 100%;
        top: 0px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
        background-color: rgba(0, 0, 0, 0);
        z-index: 100;
        padding-left: 55px;
        padding-top: 40px;
      }
    }
  }

  .story-holder {
    position: absolute;
    background-color: transparent;
    height: 100%;
    top: 80px;
    right: 100px;
    z-index: 200;

    .story-card {
      max-height: 300px;
      border: 0;
      border-radius: 0;
      width: 100%;
      box-shadow: $theme-shadow-regular !important;

      .card-body {
        padding: 40px;
      }

      .card-footer {
        border: 0;
        background-color: inherit;
        padding-left: 40px;

        .story-user-img-holder {
          width: 80px;
          height: 80px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .story-user-info-list {
          li {
            border: 0;
            background-color: transparent;
            margin-top: 2px;
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;

            &:first-child {
              font-family: $theme-font-bold;
            }
          }
        }
      }
    }
  }

  .roller-row {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
/******************************************************************************
* Blog Section
*****************************************************************************/
.blog-section {
  h2 {
    a {
      transition: 0.4s;
      &.text-#{$app-name}:hover {
        color: $theme-text-color-black !important;
      }
    }
  }
}
/******************************************************************************
* ROI Section
*****************************************************************************/
.roi-row {
  background-color: rgba(236, 0, 140, 0.1);
}
.roi-sub-heading {
  padding-left: 200px;
  padding-right: 200px;
}
.roi-calculator-holder {
  border: 1px solid #bdbdbd;
  padding: 30px;
}

.roi-nav {
  li.nav-item {
    display: inline-block;
    width: auto;
    margin: 30px;

    a.nav-link {
      display: inline-block;
      text-align: center;

      p {
        &.icon-p {
          border: 2px solid #ec008c;
          border-radius: 50%;
          padding: 30px;

          i {
            color: #ec008c;
            font-size: 2em;
          }
        }
      }

      &.shecluded-active {
        border: 0;
        p {
          &.icon-p {
            background-color: #ec008c;

            i {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/*******************************************************************************
*
* About Us Page
*
*****************************************************************************/
/******************************************************************************
* Mission Vision Section
*****************************************************************************/
.mission-vision-section {
  border: 2px solid $theme-color-black;
  border-right: 0;

  div {
    padding: 90px;

    &:first-child {
      background-color: $theme-color-black;
      text-align: right;
      color: $theme-text-color-white;

      p {
        position: absolute;
        right: 90px;
      }
    }

    p {
      width: 350px;
    }
  }
}
/******************************************************************************
* Values Section
*****************************************************************************/
.values-section {
  h2 {
    i {
      background-color: rgba(236, 0, 140, 0.05);
      border-radius: 100%;
      padding: 20px;
    }
  }

  div.justify-content-center {
    & > div {
      margin-left: 37px;
      margin-right: 34px;
    }
  }
}
/******************************************************************************
* Team members Section
*****************************************************************************/
.team-member-holder {
  %no-border {
    border: 0 !important;
  }

  .team-member {
    @extend %no-border;
    box-shadow: $theme-shadow-sm;

    .card-header {
      @extend %no-border;

      img {
        width: 100%;
        height: auto;
      }
    }

    .card-body {
      @extend %no-border;
    }
  }
}
/*******************************************************************************
*
* Loans Page
*
*****************************************************************************/
.qualities-row {
  .quality-list {
    li {
      padding-top: 13px;
      padding-bottom: 14px;
      border: 0;
    }
  }

  .check-list {
    width: auto;
    border: 0;
    box-sizing: border-box;
    display: inline-block;

    &.shecluded {
      border: 0;
      box-shadow: $theme-shadow-sm;
      border-radius: 29.5082px;
    }

    li {
      border: 0;
      padding-left: 25px;
      padding-right: 25px;
    }

    i {
      color: $theme-text-color-white;
      background-color: $theme-color-black;
      border-radius: 100%;
      padding: 5px;

      &.bg-shecluded {
        color: $theme-text-color-white;
        background-color: $theme-bg;
        border-radius: 100%;
        padding: 5px;
      }
    }
  }
}

.plans-row {
  .card {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.16);
    border: 0;

    .card-header,
    .card-footer {
      border: 0;
    }

    .card-body {
      min-height: 430px;
    }

    ul {
      font-size: $body-font-size-md;
      border: 0;

      li {
        border: 0;
      }
    }
  }
}

/*******************************************************************************
*
* Contact Us Page
*
*****************************************************************************/
.contact-info-list {
  border: 0;

  li {
    border: 0;
  }
}

.contact-form {
  .input-group {
    border-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    .form-control {
      border-left: 0;
    }
  }

  .input-group-text {
    background-color: transparent;
    color: #dadada;
  }
}

/*******************************************************************************
*
* FAQ Page
*
*****************************************************************************/
.faq-container ul li.collapser {
  font-weight: bold;
  cursor: pointer;
}

/*******************************************************************************
*
* Authentication Pages
*
*****************************************************************************/
#registerImage {
  height: 100%;
  width: 100%;
}
#registerPage {
  padding: 20px 100px;
}
.auth-row {
  form {
    label {
      font-size: $body-font-size-lg;

      &.form-check-label {
        font-size: $body-font-size;
      }
    }

    .form-group {
      margin-bottom: 20px;
    }

    %show-password {
      cursor: pointer;
      background-color: #fff;
      border-left: 0 !important;
    }
    .show-password {
      @extend %show-password;

      .input-group-text {
        @extend %show-password;
      }

      i {
        @extend %show-password;
      }

      & ~ input.form-control {
        border-right: 0 !important;
      }
    }
  }
}
.loading-container {
  width: 100%;

  span {
    font-size: 10em;
    padding: 30px;
  }
}

.status-container {
  width: 100%;

  span {
    font-size: 15em;
    padding: 20px;
  }
}

.social-auth-button {
  margin-bottom: 50px;
  width: 100%;
}

.or-bar {
  width: 3px;
  background-color: $theme-color-light-grey;
  height: 30px;
}

.or-text {
  line-height: 40px;
  margin-top: 10px;
}
/*******************************************************************************
*
* Dashboard
*
*****************************************************************************/
.input-group {
  .input-group-prepend {
    .input-group-text {
      color: $theme-text-color-white;
      background-color: $theme-bg;
      border: 1px solid $theme-bg;
    }
  }
}

.employment-type-row {
  h6 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.toggler-classname {
  margin-left: 20px;
  margin-right: 20px;

  .react-toggle-track {
    height: 10px;
    background-color: #32a636;
  }

  .react-toggle-thumb {
    top: -5px;
  }

  &.react-toggle--checked {
    &:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: $theme-color;
    }

    .react-toggle-track {
      background-color: $theme-color;
    }

    .react-toggle-thumb {
      border-color: $theme-color;
    }
  }
}
.shape {
  text-align: center;
  line-height: 50px;
  color: $theme-color-white;
  background-color: $theme-bg;
  transition: 0.6s;

  &.bar {
    width: 10px;
    height: 10px;

    &.blank {
      background-color: $theme-bg-grey-mid;
    }
  }

  &.circle {
    padding: 10px;
    border-radius: 50%;
    font-size: 12px;

    &.blank {
      background-color: $theme-bg-grey-mid;
    }

    .inner-circle {
      height: 40px;
      width: 40px;
      line-height: 20px;
      padding: 10px;
      background-color: $theme-bg-white;
      color: $theme-color-black;
      border-radius: 50%;
    }
  }

  &.pointer-right {
    position: relative;
    top: 10px;
    height: 40px;
    width: 100px;
    line-height: 40px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0;

    &:before {
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 15px solid $theme-bg;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      content: "";
      position: absolute;
      top: 0px;
      left: 100px;
    }

    &.blank {
      background-color: transparent;
      color: $theme-color-black;

      &:before {
        border-left: 30px solid transparent;
      }
    }
  }
}

.sidebar-toggler {
  position: fixed;
  width: 50px;
  height: 50px;
  left: 36px;
  top: 30px;
  z-index: 995;
  cursor: pointer;

  .bar {
    background-color: $theme-color;
    display: block;
    height: 7px;
    width: 50px;
    margin-bottom: 8px;
  }
}

/*******************************************************************************
* Dashboard cards
*****************************************************************************/
.dashboard-card {
  box-shadow: -10px 10px 20px rgba(30, 30, 30, 0.05);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 85px;
}

#remove-decoration {
  &:hover {
    text-decoration: none;
  }
}

/*******************************************************************************
* Credit card 
*****************************************************************************/
.credit-card {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 220px;
  text-align: left;
  padding: 30px;
  margin-bottom: 50px;
  color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  background: $theme-bg;

  &__number {
    font-size: $body-font-size-lg;
    padding: 20px 0 15px;
    text-align: center;
  }

  &__expiry-date {
    font-size: 14px;
    padding-bottom: 20px;
    text-align: center;
  }

  &__owner {
    text-align: left;
  }

  &__logo {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &__strip {
    background: #000;
    height: 50px;
    margin-bottom: 30px;
  }

  &__signature {
    float: left;
    width: 65%;
    height: 40px;
    margin-left: 10px;
    background: #999;
  }

  &__ccv {
    float: left;
    margin-top: 5px;
    padding: 7px;
    background: #fff;
    color: #111;
    line-height: 1;
  }

  &--front {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
  }

  &--back {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
}

/*******************************************************************************
* Loans manager
*****************************************************************************/
.loan-switcher {
  cursor: pointer;
  padding-left: 20px;

  li {
    display: inline-block;
    margin-right: 30px;
    font-size: $body-font-size-md;
    border-bottom: 2px solid $theme-color-black;

    &.current {
      color: $theme-color;
      border-bottom: 2px solid $theme-color;
      font-weight: bold;
    }
  }
}

td.repayment {
  padding: 27px;
}
.repayment-schedule {
  padding-left: 50px;
}

tr.collapser {
  cursor: pointer;
}

//confirm alert
#react-confirm-alert-firm-svg {
  display: none;
}
#react-confirm-alert {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 990;

  .react-confirm-alert-overlay {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.98);
    height: 100vh;

    .confirm-info {
      border-radius: 5px;
      background-color: #fff;
      padding: 30px;
      top: 200px;
    }
  }
}

/*******************************************************************************
* Request Loan
*****************************************************************************/
textarea[name="pitch_text"] {
  height: 42px;
  resize: none;
}

.request-complete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 107%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;

  .main-content {
    background-color: #fff;
    padding: 10px;
    padding-top: 50px;
    height: 500px;

    a.btn {
      width: 350px;
    }
    & > div.row {
      margin-bottom: 48px;
    }
  }
}
/*******************************************************************************
* Verify Card notifier
*****************************************************************************/
.verify-card-notifier {
  position: fixed;
  right: 20px;
  bottom: 10px;
  padding: 30px;
  font-size: 20px;
  background-color: rgba(236, 0, 140, 0.6);
  border-radius: 10px;
  z-index: 1000;
}
/*******************************************************************************
*
* Preloader
*
*****************************************************************************/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: none repeat scroll 0 0 #ffffff;
}

.spinner {
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65px;
  height: 65px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid $theme-color;
  border-top-color: #ffffff;
  animation: rotating 0.9s linear infinite;
}

/*******************************************************************************
*
* Animations
*
*****************************************************************************/
//rotating
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
//Pulsating
@keyframes pulsating {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulsating {
  -webkit-animation: pulsating 1s linear infinite;
  -moz-animation: pulsating 1s linear infinite;
  -ms-animation: pulsating 1s linear infinite;
  -o-animation: pulsating 1s linear infinite;
  animation: pulsating 1s linear infinite;
}

/* Gbenga's styles */
.user-layout {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.container-fluid.main-container.user {
  background: #f5f5f5;
}
.dashboard-header-container {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15);

  .card-header {
    background: white;
  }
  .card-body {
    overflow-x: auto;

    table {
      .btn {
        width: 100%;
      }
    }
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-header-content .fa {
  color: #ec008c;
}

.dashboard-header-content:nth-child(1),
.dashboard-header-content:nth-child(2) {
  border-right: 1px solid #ec008c;
}

.dashboard-middle {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  .dashboard-middle-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 150px;
    padding: 20px;
    background: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15);

    &:hover {
      transform: scale(1.01);
    }
  }
}

.dashboard-bottom {
  margin: 50px 0px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;

  .dashboard-bottom-right {
    overflow-x: auto;

    tr {
      padding: 10px;
    }
  }

  .dashboard-bottom-left {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .dashboard-bottom-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 150px;
      padding: 20px;
      background: white;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15);

      &:hover {
        transform: scale(1.01);
      }
    }

    .invite-a-friend-card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      min-height: 150px;
      color: white;
      background: #ec008c;
      padding: 20px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15);

      &:hover {
        transform: scale(1.01);
      }
    }
  }
}

/*******************************************************************************
* Sidebar (burger menu) stying
*****************************************************************************/
.dashboard-main {
  margin-top: 120px;
  padding: 20px;
}
.dashboard-sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 120px;
  padding: 20px;

  .btn.btn-shecluded {
    width: 100%;
  }

  .dashboard-sidebar-card {
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .dashboard-social-media-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }

  img.profile-picture {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 50%;
  }
}

// Manage Profile Section
.manage-profile-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .fa {
    color: #ec008c;
    margin-right: 10px;
  }
}

.manage-profile-header {
  display: flex;
  border-bottom: 1px solid #ec008c;

  h6 {
    margin-right: 40px;

    &.active {
      color: #c40074;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      color: #ec008c;
    }
  }
}

.manage-profile-card {
  width: 100%;
  padding: 50px;
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15);
}
.manage-profile {
  padding: 50px;
}
.manage-profile-form-fields {
  padding-top: 50px;

  .btn.btn-shecluded {
    width: 100%;
  }
}
.loan-table {
  margin: 5px 0px;
  display: grid;
  background: white;
  padding: 20px;
  grid-template-columns: 1fr;

  .loan-table-body {
    overflow-x: auto;

    tr {
      padding: 10px;
    }
  }
}
.loan-status-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loan-status-button {
  height: 15px;
  width: 15px;
  padding: 0px;
  border-radius: 50%;
}

.sources-of-income-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fa {
    color: #ec008c;
    margin-right: 10px;
  }
}

.sources-of-income-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-top: 20px;

  .body-layout {
    display: grid;
    grid-template-columns: 2fr 1fr;

    .body-layout-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .fa {
        color: #c40074;
      }

      h6 {
        margin-bottom: 0px;
        line-height: unset;
        font-size: 14px;
      }
    }
  }
}

.registration-page-layout {
  position: relative;
  width: 40%;
  left: 30%;

  .btn.btn-shecluded {
    width: 100%;

    &.sidehustle-button {
      width: 40%;
      position: relative;
      left: 30%;
    }
  }

  .source-of-income-fields {
    .sidehustle-span {
      text-align: right;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
.sources-of-income-navigation-buttons {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  overflow-x: auto;
  margin-bottom: 40px;

  div {
    text-align: center;
    border: 1px solid black;
    border-radius: 3px;
    padding: 10px 2px;

    &.active {
      background: #ec008c;
      border: unset;
      color: white;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.fa-shecluded {
  color: #c40074;
}

//Admin Layout
.admin-container.user {
  min-height: 100vh;
  background: $theme-color-light-grey;

  .card {
    .card-header {
      background-color: $theme-bg-white !important;
    }
  }
}
.admin-layout {
  display: grid;
  grid-template-columns: 1fr 10fr;
}
.admin-sidebar {
  background: #ec008c;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 20px 0px;
  min-height: 100vh;

  .place-sidebar-icons {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    a {
      width: 100%;

      &.selected {
        i {
          background: $theme-color-light-grey;
          color: $theme-color;
        }
      }

      i {
        color: #fff;
        padding: 15px 0px;
        width: 100%;
        font-size: 125%;

        small {
          font-size: 12px !important;
          font-family: $theme-font-regular !important;
        }

        &:hover {
          background: $theme-color-dark-grey;
          color: $theme-color;
        }
      }
    }
  }
}

.registration-stepper {
  position: relative;
  width: 100%;

  .registration-stepper-background {
    position: absolute;
    height: 10%;
    top: 45%;
    width: 100%;
    background: #ec008c;
  }
  .active {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $theme-color-light-grey;
    position: relative;
    width: 50%;
    margin: 0 auto;
    text-align: center;

    h5 {
      padding: 0 20px;
    }
  }
}

.admin-main {
  margin-top: 120px;
  padding: 20px 100px;
  overflow-y: auto;
  min-height: 82vh;
  max-height: 100vh; // I changed it from 82vh to 100vh

  .admin-loan-details {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 20px;

    .card.left {
      max-height: 100vh;
      overflow-y: auto;
    }
    .card.right {
      max-height: 100vh;
      overflow-y: auto;
    }

    .scroll-details {
      overflow-x: auto;
    }
  }

  .box {
    border: 1px solid $theme-color-black;
    padding: 10px 20px 10px 5px;
    width: 100%;
  }
}

.position-chart {
  .chartjs-render-monitor {
    min-height: 500px !important;
    max-height: 500px !important;
  }
}

.admin-dashboard-middle {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  .admin-dashboard-middle-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    min-height: 150px;
    padding: 10px 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15);

    &:hover {
      transform: scale(1.01);
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        color: $theme-color;
      }
    }
  }
}
